import React from "react";
import "./home.css";
import Particle from "../Particle";
function Home() {
  return (
    <>
      <Particle />
      <section>
        <div className="container">
          <div className="coming_soon">
            <div></div>
            <div className="wrapper">
              <div className="header">
                <p className="sm_text">our website is</p>
                <div className="main_text">Coming Soon</div>
                <p className="main_para">
                  Our website is coming soon. we are currently working on this
                  website!
                </p>
              </div>
            </div>
            <div className="socials">
              <a href="#0">
                <span className="op-facebook"></span>
              </a>
              <a href="#0">
                <span className="op-twitter"></span>
              </a>
              <a href="#0">
                <span className="op-linkedin"></span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
